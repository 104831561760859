import '../App.css';
import React from 'react';

/**
 * Renders a modal component for uploading a job description file.
 *
 * @param {Object} args - The arguments for the JDUploadModal component.
 * @returns {JSX.Element} The rendered JDUploadModal component.
 */
const JDUploadModal = (args) => {
    const jobDescriptionUpload = (event) => {
        const descriptionFile = event.target.files[0];
        args.SetDescriptionUpload(descriptionFile);
    }

    return (
        <div className="modal fade" tabIndex="-1" id="selectFileModalJobDescription">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Select Job Description File</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <input type="file" id='ctrl' accept='.pdf, .doc, .docx, .txt' onChange={jobDescriptionUpload}/>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary" data-bs-dismiss="modal">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JDUploadModal;