import '../App.css';
import React from 'react';
import fileIcon from '../assets/fileIcon.svg';
import dbIcon from '../assets/dbIcon.svg';

/**
 * Renders the home page of the application.
 *
 * @param {Object} args - The arguments passed to the component.
 * @returns {JSX.Element} The rendered home page.
 */
const HomePage = (args) => {   

    return (
        <>
            <div className='d-flex justify-content-center' style={{"margin-top":"50px"}}>
                <h1 style={{"color":"white"}}>Interview AI Helper</h1>
            </div>
            <div className='d-flex justify-content-evenly' style={{"margin-top":"100px"}}>

                <div className='customCard' onClick={args.loadDB}>
                    <div className='d-flex justify-content-center' style={{"margin-top": "50px"}}>
                        <img src={dbIcon} alt='Database Icon' className='cardIcon'></img>
                    </div>
                    <div style={{"margin-top": "50px"}}>
                        <h2 className='cardInfo'>
                            View Past Interviews
                        </h2>
                    </div>
                </div>

                <div className='customCard' onClick={() => args.setNewInterview(true)}>
                    <div className='d-flex justify-content-center' style={{"margin-top": "25px"}}>
                        <img src={fileIcon} alt='File Icon' className='cardIcon'></img>
                    </div>
                    <div style={{"margin-top": "25px"}}>
                        <h2 className='cardInfo'>
                            Generate New Interview Notes
                        </h2>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePage;