import '../App.css';
import React from 'react';
const backend_key = process.env.REACT_APP_BACKEND_API;

/**
 * DeleteInterviewModal component.
 * 
 * @param {Object} args - The arguments object.
 * @param {boolean} args.setLoading - Function to set the loading state.
 * @param {boolean} args.setHome - Function to set the home state.
 * @param {string} args.deleteUuid - The UUID of the interview to be deleted.
 * @param {boolean} args.setViewDB - Function to set the viewDB state.
 * @param {boolean} args.loadDB - Function to load the interview database.
 * @param {string} args.deleteName - The name of the interviewee.
 * @param {string} args.deleteJob - The role for which the interview was conducted.
 * @returns {JSX.Element} The DeleteInterviewModal component.
 */
const DeleteInterviewModal = (args) => {

    const deleteInterview = () => {
        args.setLoading(true);
        args.setHome(false);
        fetch(`/deleteInterview`, {
            method: 'POST',
            headers: {
				'Content-Type': 'application/json',
				'my-custom-header-key': backend_key,
			},
			credentials: 'include',
            body: JSON.stringify({uuid: args.deleteUuid}),
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            console.log(data);
            args.setViewDB(true);
            args.setHome(true);
            args.setLoading(false);
            args.loadDB();
            alert('Interview Log Deleted.')
        })
        .catch(error => {
            alert('Error Deleting Interview.')
            console.error(`${error}`);
        });
    }

    return (
        <>
            <div className="modal fade" tabIndex="-1" id="deleteInterviewModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Interview Data</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                {`Are you sure you want to delete ${args.deleteName}'s interview for the role of ${args.deleteJob}?`} 
                            </p>
                            <p>
                                THIS CANNOT BE UNDONE!
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button className="btn btn-danger" data-bs-dismiss="modal" onClick={() => deleteInterview()}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteInterviewModal;