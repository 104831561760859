import './App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider, useMsal } from '@azure/msal-react';
import { loginRequest } from './auth-config';
import InterviewGPT from './InterviewGPT';

// propTypes is a development time validation library that helps you catch errors by validating your props with types.
import PropTypes from 'prop-types';

/**
 * A wrapped view component that handles authentication and rendering of InterviewGPT component.
 *
 * @returns {JSX.Element} The wrapped view component.
 */
const WrappedView = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const handleRedirect = () => {
        instance
            .loginRedirect({
                ...loginRequest,
                prompt: 'create',
            })
            .catch((error) => console.log(error));
    }

    return (
        <div className='App'>
            <AuthenticatedTemplate>
                {activeAccount ? (
                    <InterviewGPT user={activeAccount.name}/>
                ):null}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className='signInFlex d-flex justify-content-center align-items-center'>
                    <button className='signInBtn' onClick={handleRedirect}>
                        Sign In
                    </button>
                </div>
            </UnauthenticatedTemplate>
        </div>
    )
};

/**
 * Renders the main application component.
 * @param {Object} props - The component props.
 * @param {Object} props.instance - The instance of MsalProvider.
 * @returns {JSX.Element} The rendered component.
 */
const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <WrappedView/>
        </MsalProvider>
    );
};

App.propTypes = {
    instance: PropTypes.object.isRequired,
};

export default App;