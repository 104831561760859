import './App.css';
import React, {useState} from 'react';
import DisplayInterview from './pages/DisplayInterview';
import InterviewDB from './pages/InterviewDB';
import HomePage from './pages/HomePage';
import NewInterview from './pages/NewInterview';
import Loading from './pages/Loading';
const backend_key = process.env.REACT_APP_BACKEND_API;

/**
 * Component for managing the InterviewGPT functionality.
 *
 * @returns {JSX.Element} The InterviewGPT component.
 */
const InterviewGPT = ({user}) => {
	/**
	 * All these state variables are used in AT LEAST 2 jsx components and so they are defined here and passed through args
	 * At a later point, the code should be split up further. Currently, nearly all jsx "components" are just entire pages. 
	 * 
	 * Various boolean state variables to control the flow of the application (which page to display when a specific event occurs).
	 * Can be tricky to follow so may be refactored later.
	*/
	const [home, setHome] = useState(true);
	const [loading, setLoading] = useState(false);
	const [AIGenLoading, setAIGenLoading] = useState(false);
	const [viewDB, setViewDB] = useState(false);
	const [newInterview, setNewInterview] = useState(false);

	// A single interview which is passed to DisplayInterview component to display the generated data and notes page
	const [candidateInfo, setCandidateInfo] = useState();

	// State variable containing all of the interviews previously generated, gotten from the db to be displayed in the db user interface page 
	const [interviewsData, setInterviewsData] = useState([]);

	const loadDB = () => {
        setLoading(true);
        setHome(false);
    
        fetch('/getAll', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'my-custom-header-key': backend_key,
			},
			credentials: 'include'
		})
        .then((response) => response.json())
        .then((data) => {
            setInterviewsData(data || []);
            setViewDB(true);
            setHome(true);
            setLoading(false);
        })
        .catch((error) => {
			alert("Trouble retrieving Data. Server May be down. Check Logs");
            console.error('Error:', error);
        });
    }

	const handleSaveCandidate = (saveData) => {
		fetch('/saveCandidate', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'my-custom-header-key': backend_key,
			},
			credentials: 'include',
			body: JSON.stringify({...saveData, user:user}),
		})
		.then((response) => response.json())
		.then((data) => {
			console.log(data);
		})
		.catch((error) => {
			alert("Trouble Saving Data. Check Logs.")
			console.error('Error:', error);
		});
    }

	return (
		<>
			{!home ? (
				<>
					{loading ? (
						<Loading AIGenLoading={AIGenLoading}/>
					): (
						<DisplayInterview candidateInfo={candidateInfo} setCandidateInfo={setCandidateInfo} setHome={setHome} handleSaveCandidate={handleSaveCandidate}/>
					)}
				</>
			): (
				<>
					{newInterview ? (
						<>
							<button className='fixed-top btn btn-info' style={{"margin": "20px 0 0 20px", "width":"100px"}} onClick={() => setNewInterview(false)}>{'<-- Back'}</button>
							<NewInterview setHome={setHome} setAIGenLoading={setAIGenLoading} setLoading={setLoading} setCandidateInfo={setCandidateInfo} setNewInterview={setNewInterview} handleSaveCandidate={handleSaveCandidate}/>
						</>
					):(
						viewDB ? (
							<>
								<button className='fixed-top btn btn-info' style={{"margin": "20px 0 0 20px", "width":"100px"}} onClick={() => setViewDB(false)}>{'<-- Back'}</button>
								<InterviewDB loadDB={loadDB} interviewsData={interviewsData} setLoading={setLoading} setHome={setHome} setViewDB={setViewDB} setCandidateInfo={setCandidateInfo}/>
							</>
						):(
							<HomePage loadDB={loadDB} setLoading={setLoading} setHome={setHome} setInterviewsData={setInterviewsData} setViewDB={setViewDB} setNewInterview={setNewInterview}/>
						)
					)}
				</>
			)}
			<div id='modal-root'></div>
		</>
	);
}

export default InterviewGPT;