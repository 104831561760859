import '../App.css';
import React from 'react';

/**
 * Renders a modal component for uploading a resume file.
 *
 * @param {Object} args - The arguments for the component.
 * @param {Function} args.SetResumeUploaded - The function to set the uploaded resume file.
 * @returns {JSX.Element} The rendered modal component.
 */
const ResumeUploadModal = (args) => {
    const resumeUpload = (event) =>{
        const resumeFile = event.target.files[0];
        args.SetResumeUploaded(resumeFile);
    }

    return (
        <div className="modal fade" tabIndex="-1" id="selectFileModalResume">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Select Resume</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <input type="file" id='ctrl' accept='.pdf, .doc, .docx, .txt' onChange={resumeUpload}/>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary" data-bs-dismiss="modal">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResumeUploadModal;